import { environment } from "../../environments/environment";

import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Invite, CreateInvite } from "../models/invite";
import { AuthService } from "./auth.service";
import * as Moment from "moment";
import { Observable, BehaviorSubject } from "rxjs";

const apiUrl = `${environment.backend}/v1/invites`;

@Injectable({
    providedIn: "root",
})
export class InvitesService {
    private _inviteList: BehaviorSubject<Invite[]> = new BehaviorSubject(null);
    public readonly inviteList: Observable<
        Invite[]
    > = this._inviteList.asObservable();
    lastLoaded: Moment.Moment;
    cacheExpiryTime: Moment.Duration = Moment.duration(1, "minutes");

    constructor(private http: HttpClient, private authService: AuthService) {
        this.loadInvites();
        this.inviteList.subscribe((val) => console.log(val));
    }

    cacheHasExpired(): boolean {
        return (
            !this._inviteList.getValue() ||
            Moment(this.lastLoaded).add(this.cacheExpiryTime).isBefore(Moment())
        );
    }

    async loadInvites() {
        const invites = await this.http
            .get<Invite[]>(`${apiUrl}`, await this.httpOptions)
            .toPromise()
            .catch((err) => {
                return new Array<Invite>();
            });
        const currentInviteList = this._inviteList.getValue();

        this._inviteList.next(invites);
        this.lastLoaded = Moment();
    }

    get httpOptions() {
        return new Promise(async (resolve) => {
            const userToken = await this.authService.getUserToken();
            resolve({
                headers: new HttpHeaders({
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: `Bearer ${userToken}`,
                }),
            });
        });
    }

    async getInvite(id: string): Promise<Invite> {
        return this.cacheHasExpired()
            ? await this.http
                  .get<Invite>(`${apiUrl}/${id}`, await this.httpOptions)
                  .toPromise()
            : this._inviteList.getValue().find((invite) => invite._id == id);
    }

    async createInvite(invite: CreateInvite) {
        return this.http
            .post(`${apiUrl}`, invite, await this.httpOptions)
            .toPromise()
            .then((invite: Invite) => {
                const next: Invite[] = this._inviteList.getValue();
                next.push(invite);
                this._inviteList.next(next);
                this.loadInvites();
                console.log(invite);
                return invite;
            });
    }
}
