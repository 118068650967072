import { Component, OnInit } from "@angular/core";
import { InvitesService } from "src/app/services/invites.service";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";

@Component({
    selector: "app-create-invite",
    templateUrl: "./create-invite.component.html",
    styleUrls: ["./create-invite.component.scss"],
})
export class CreateInviteComponent implements OnInit {
    inviteeNameText: string = "";
    inviteeEmailText: string = "";
    platformText: string = "write";

    serverErrorMessage: string;

    loading: boolean = false;

    constructor(
        private router: Router,
        private invitesService: InvitesService
    ) {}

    ngOnInit() {}

    async onSubmit(inviteForm) {
        if (this.loading) return;
        this.loading = true;
        this.serverErrorMessage = null;

        if (!inviteForm.valid) {
            this.loading = false;
            return;
        }

        await this.invitesService
            .createInvite({
                invitee: {
                    name: this.inviteeNameText,
                    email: this.inviteeEmailText,
                },
                platform: this.platformText,
            })
            .then((res: any) => {
                this.loading = false;
                this.router.navigateByUrl("/invites");
            })
            .catch((error) => {
                console.error(error);
                this.serverErrorMessage =
                    "There was an error creating your invite.";
                this.loading = false;
            });
    }
}
