import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import {
    AngularFireAuthGuard,
    redirectUnauthorizedTo,
    redirectLoggedInTo,
} from "@angular/fire/auth-guard";

import { LoginComponent } from "./components/login/login.component";
import { InvitesComponent } from "./components/invites/invites.component";
import { LogoutComponent } from "./components/logout/logout.component";
import { LogoutGuard } from "./services/logout-guard.service";
import { CreateInviteComponent } from "./components/create-invite/create-invite.component";

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(["login"]);
const redirectLoggedInToInvites = () =>
    redirectLoggedInTo(["invites"]);

const routes: Routes = [
    { path: "", redirectTo: "/login", pathMatch: "full" },
    {
        path: "login",
        component: LoginComponent,
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: redirectLoggedInToInvites },
    },
    {
        path: "invites",
        component: InvitesComponent,
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: redirectUnauthorizedToLogin },
    },
    {
        path: "invites/create",
        component: CreateInviteComponent,
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: redirectUnauthorizedToLogin },
    },
    {
        path: "logout",
        component: LogoutComponent,
        canActivate: [AngularFireAuthGuard, LogoutGuard],
        data: { authGuardPipe: redirectUnauthorizedToLogin },
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
