import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/services/auth.service";
import { Router } from "@angular/router";

@Component({
    selector: "app-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit {
    emailText: string = "";
    passwordText: string = "";
    serverErrorMessage: string = null;
    loadingEmail: boolean = false;
    loadingGoogle: boolean = false;
    loadingFacebook: boolean = false;

    constructor(private router: Router, private authService: AuthService) {}

    ngOnInit() {}

    onSubmit(loginForm) {
        if (this.loadingEmail || this.loadingFacebook || this.loadingGoogle)
            return;
        this.loadingEmail = true;
        this.serverErrorMessage = null;
        if (!loginForm.valid) {
            this.loadingEmail = false;
            return;
        }
        this.authService
            .doEmailPasswordLogin(this.emailText, this.passwordText)
            .then(res => {
                this.loadingEmail = false;
                this.redirect();
            })
            .catch(err => {
                this.serverErrorMessage = err.message;
                this.loadingEmail = false;
            });
    }

    facebookLogin() {
        if (this.loadingEmail || this.loadingFacebook || this.loadingGoogle)
            return;
        this.loadingFacebook = true;
        this.authService
            .doFacebookLogin()
            .then(res => {
                this.loadingFacebook = false;
                this.redirect();
            })
            .catch(err => {
                this.loadingFacebook = false;
            });
    }

    googleLogin() {
        if (this.loadingEmail || this.loadingFacebook || this.loadingGoogle)
            return;
        this.loadingGoogle = true;
        this.authService
            .doGoogleLogin()
            .then(res => {
                this.loadingGoogle = false;
                this.redirect();
            })
            .catch(err => {
                this.loadingGoogle = false;
            });
    }

    redirect() {
        console.log("redirect");
        this.router.navigateByUrl("/invites");
    }
}
