import { environment } from "../environments/environment";

import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";

import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import {
    AngularFireAnalyticsModule,
    UserTrackingService,
    ScreenTrackingService,
} from "@angular/fire/analytics";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
    MatBottomSheetModule,
    MatButtonModule,
    MatCardModule,
    MatDatepickerModule,
    MatDialogModule,
    MatGridListModule,
    MatInputModule,
    MatNativeDateModule,
    MatSnackBarModule,
    MAT_DATE_LOCALE,
    MatSelectModule,
} from "@angular/material";

import { DialogComponent } from "./components/dialog/dialog.component";
import { MaterialElevationDirective } from "./styling/material-elevation.directive";
import { LoginComponent } from "./components/login/login.component";
import { InvitesComponent } from "./components/invites/invites.component";
import { AuthService } from "./services/auth.service";
import { SpinnerComponent } from "./components/widgets/spinner/spinner.component";
import { LogoutComponent } from "./components/logout/logout.component";
import { AngularFireAuthGuard } from "@angular/fire/auth-guard";
import { LogoutGuard } from "./services/logout-guard.service";
import { CreateInviteComponent } from "./components/create-invite/create-invite.component";

@NgModule({
    declarations: [
        AppComponent,
        DialogComponent,
        MaterialElevationDirective,
        LoginComponent,
        InvitesComponent,
        SpinnerComponent,
        LogoutComponent,
        CreateInviteComponent,
    ],
    entryComponents: [DialogComponent],
    imports: [
        BrowserModule,
        HttpClientModule,
        FormsModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAuthModule,
        AngularFireAnalyticsModule,
        MatBottomSheetModule,
        MatButtonModule,
        MatCardModule,
        MatDatepickerModule,
        MatDialogModule,
        MatGridListModule,
        MatInputModule,
        MatNativeDateModule,
        MatSelectModule,
        MatSnackBarModule,
    ],
    providers: [
        AuthService,
        AngularFireAuthGuard,
        LogoutGuard,
        MatDatepickerModule,
        { provide: MAT_DATE_LOCALE, useValue: "en-GB" },
        UserTrackingService,
        ScreenTrackingService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
