import * as Moment from "moment";
import { Component, OnInit } from "@angular/core";

import { InvitesService } from "src/app/services/invites.service";
import { Invite } from "src/app/models/invite";

@Component({
    selector: "app-invites",
    templateUrl: "./invites.component.html",
    styleUrls: ["./invites.component.scss"],
})
export class InvitesComponent implements OnInit {
    invites: Invite[] = null;
    loadinginvites: boolean = true;

    get anyInvites(): boolean {
        return this.invites && this.invites.length > 0;
    }

    get anyPendingInvites(): boolean {
        return !this.anyInvites ? false : this.pendingInvites.length > 0;
    }

    constructor(private invitesService: InvitesService) {
        this.invitesService.inviteList.subscribe((invites: Invite[]) => {
            this.invites = invites;
        });
    }

    get pendingInvites(): Invite[] {
        return this.invites.filter((invite: Invite) => !invite.accepted);
    }

    get acceptedInvites(): Invite[] {
        return this.invites.filter((invite: Invite) => invite.accepted);
    }

    get loaded(): boolean {
        return this.invites !== null;
    }

    ngOnInit() {}

    getStringForTime(time: string) {
        return Moment(time).format("HH:mm");
    }

    reload() {
        this.invitesService.loadInvites();
    }
}
